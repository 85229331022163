import $ from 'jquery';
//import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');
});